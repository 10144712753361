@import '../../../../../../node_modules/mode-front-end/resources/assets/sass/common';
@import '../../base/common-redesign';

main {
  background-image: url(cache-ts-url('/img/pinnacle/backgrounds/map.webp'));
  background-repeat: no-repeat;
  background-position: right 76%;
  background-size: 200%;

  @include mq('xl') {
    background-position: right 66%;
    background-size: contain;

  }
}

.home{
  .o-video.about-home{
    margin-top: 85px;
    margin-bottom: 73px;

    @include mq('xl') {
      margin-top: scale-1200(184px);
      margin-bottom: scale-1200(140px);
    }
  }
}

.quality-promise {

  padding-top: 85px;
  padding-bottom: 75px;

  @include mq('xl') {
    padding-top: scale-1200(184px);
    padding-bottom: scale-1200(184px);
  }

  .section-header__heading-img {
    @include mq('xl') {
      width: scale-1200(443px);
    }
  }

  .section-header__copy {
    @include body--lg();
  }
}

.feature-carousel {
  padding-top: 0;
  padding-bottom: 30px;

  @include mq('lg') {
    padding-top: scale-1200(115px);
    padding-bottom: scale-1200(115px);
  }
}

