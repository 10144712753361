@charset "UTF-8";
/**
 * Escape special characters in a selector.
 * @param  {String}  $pixels
 * @return {String}
 */
/**
 * Remove the unit of a length
 * @see https://hugogiraudel.com/2013/08/12/sass-functions/
 * @param  {Number}  $value  Number to remove unit from
 * @return {Number}          Unitless number
 */
/**
 * Convert px values (e.g. from a PSD) to ems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @param  {Number}  $context  Defaults to $font-size
 * @return {Number}            em value
 */
/**
 * Convert px values (e.g. from a PSD) to rems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @return {Number}           rem value
 */
/**
 * Subtract 1px from breakpoint so min/max queries don't overlap.
 * @todo Use 0.01em instead of 1px to avoid media query "void" when text zoom is on.
 * @see https://github.com/sass-mq/sass-mq/issues/6
 * @param  {Number}  $breakpoint
 * @return {Number}
 */
/**
 * Convert tracking (e.g. from a PSD) to ems for letter-spacing.
 * @param  {Number}  $tracking
 * @return {Number}             em value
 */
/**
 * Convert px letter spacing to em based on font size
 */
/**
 * Convert line height to unit relative to the font size
 * @param % or px value
 */
/**
 * Uses clamp to set a fluid valid with min/max values using clamp()
 * By default, this uses $breakpoint-min and $breakpoint-max
 *
 * @param px value min
 * @param px value max at design size
 * @param bp-min
 * @param bp-max
 *
 */
/**
 * Casting any value to a string.
 * @see https://hugogiraudel.com/2014/01/27/casting-types-in-sass/
 * @param  {mixed}   $value
 * @return {String}
 */
/**
 * Replace `$search` with `$replace` in `$string`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/5c85bcae6ab51ef3996719ec31cce7aafd1a9fad/stylesheets/private/_str-replace.scss
 * @param  {String}  $string       - Initial string
 * @param  {String}  $search       - Substring to replace
 * @param  {String}  $replace ('') - New value
 * @return {String} - Updated string
 */
/**
 * Split `$string` into several parts using `$delimiter`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/c587b74ad96532eb47ec8509deb8c212ba75478c/stylesheets/private/_str-explode.scss
 * @param  {String}  $string         - String to split
 * @param  {String}  $delimiter ('') - String to use as a delimiter to split `$string`
 * @return {List}
 */
/**
 * Global breakpoints for your application.
 * @note Always set the `em` context to 16 when you're using them for media
 * queries. Otherwise, if you change `$font-size` you'll get wonky values that
 * technically work but aren't what you wanted.
 * @see https://zellwk.com/blog/media-query-units/
 * @type {Map}
 */
/**
 * Accessible hover styles. Applies :hover styles to :focus and :active.
 * @see https://24ways.org/2007/css-for-accessibility
 */
/**
 * Hide only visually, but have it available for screen readers:
 * @see https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
/**
 * Keep an element invisible until an active class is added to it.
 * @param  {String}  $active-class
 */
/**
 * From Foundation's visibility classes
 * @see https://github.com/zurb/foundation-sites/blob/develop/scss/components/_visibility.scss
 * @see https://foundation.zurb.com/sites/docs/v/5.5.3/components/visibility.html
 */
/**
 * Get full path to font file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Get full path to image file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Clear floated elements.
 * @see https://css-tricks.com/snippets/css/clear-fix/
 */
/**
 * Polyfill object-fit and object-position.
 * Values: `fill`, `contain`, `cover`, `none`, `scale-down`.
 * @note Requires polyfill for IE and Edge.
 * @see https://caniuse.com/#feat=object-fit
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
 * @see https://github.com/bfred-it/object-fit-images
 * @param  {String}  $value
 */
/**
 * Input placeholders
 * @see https://github.com/twbs/bootstrap/blob/c04aa41ee2744b769be38526bbdef11e5be9421b/less/mixins/vendor-prefixes.less#L100-L109
 */
/**
 * Generic property utility.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Directional property utility.
 * Valid aliases: `top`, `gray`, `gray-3`, `top-gray`, `top-gray-3`.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Generate fluid padding. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid margin. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid spacing. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate a fluid property class. e.g u-padding-top-sm
 *
 * @param $property - e.g margin-top, padding-bottom
 * @param $alias - simple string indicating the size. e.g sm, lg
 * @param $value - list (min px, max px)
 * @param $bp-min - min breakpoint
 * @param $bp-max - max-breakpoint
 */
/**
 * Add new breakpoint alias.
 * @see https://oddbird.net/2013/10/19/map-merge
 * @see https://github.com/sass/sass/issues/1511#issuecomment-61907897
 *
 * Example:
 * `@include add-breakpoint('sidebar', 60em);`
 *
 * @param  {String}  $alias
 * @param  {Number}  $value
 * @return {Boolean}
 */
/**
 * Generate media queries based on a map of `$breakpoints`. Defaults to
 * mobile-first, min-width queries.
 *
 * Allows quick prefixes to modify breakpoints:
 * - `lt-`: Desktop-first, max-width queries (automatically removes 1px to avoid query overlap)
 * - `btwn-`: Between min- and max-width queries
 *
 * Based on Inuit's responsive tools:
 * @see https://github.com/inuitcss/settings.responsive/blob/master/_settings.responsive.scss
 * @see https://github.com/inuitcss/tools.responsive/blob/master/_tools.responsive.scss
 *
 * And Sass MQ:
 * @see https://github.com/sass-mq/sass-mq
 *
 * @note `$breakpoints` is defined in `resources/assets/sass/settings/_breakpoints.scss`
 *
 * @param  {String}  $breakpoint-name
 */
/**
 * Generate breakpoint-specific classes.
 * @param  {String}  $alias
 * @param  {String}  $at-breakpoint
 */
/**
 * Build an SVG. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String}  $icon
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Build an SVG path. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String} $path
 * @param  {Map}    $parameters
 * @return {String}
 */
/**
 * Optimize SVGs in data URIs. Based on Taylor Hunt's and jakob-e's work.
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * @see https://codepen.io/jakob-e/pen/doMoML
 * @param  {String}  $svg
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Font smoothing on OS X.
 * @see https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 * @param  {Boolean}  $value
 * @return {[type]}
 */
/*doc
---
title: Grid
name: grid
category: Objects
---
## Sass

```scss
// Basic
@include o-grid();
@include o-grid__item();

// Responsive
@include o-grid('sm');
```

## Markup

### Basic

```html_example
<div class="o-grid">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid@sm">
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Grid object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Grid Gutters
name: grid-gutters
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-grid--gutters('sm', $gutter-sm);

// Responsive
@include o-grid--gutters('sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="o-grid  o-grid--gutters-sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid  o-grid--gutters-sm@sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid with gutters.
 * @param  {String}  $alias
 * @param  {Number}  $size
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Icon
name: icon
category: Objects
---
<span class="todo">IMPORTANT:</span> When preparing SVGs:

1. Remove every `fill` and `stroke` attribute that should inherit its color from CSS.
2. Set `fill="none"` or `stroke="none"` anywhere a color should _not_ be applied.

## Sass

```scss
// Basic
@include o-icon();
@include o-icon--size(70, 70);

// Responsive
@include o-icon('sm');
@include o-icon--size(120, 120, 'sm');
```

## Markup

### Basic

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```

### Responsive

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70  o-icon--120x120@sm">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```
*/
/**
 * Icon object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Icon size helper.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List
name: list
category: Objects
---
## Sass

```scss
// Basic
@include o-list();
@include o-list__item();

// Responsive
@include o-list('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * List object.
 * @param  {String}  $at-breakpoint
 */
/**
 * List object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Border
name: list-border
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-list--border('', 1px solid $color-gray-3);

// Responsive
@include o-list--border('', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--border">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--border@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Block list separated by borders.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Bullet
name: list-bullet
category: Objects
---
## Sass

```scss
// Basic
@include o-list--bullet('', 1rem);

// Responsive
@include o-list--bullet('', 1rem, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--bullet">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--bullet@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list.
 * @param  {String}  $alias
 * @param  {Number}  $margin
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Comma
name: list-comma
category: Objects
---
## Sass

```scss
// Basic
@include o-list--comma();

// Responsive
@include o-list--comma('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--comma">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--comma@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by commas.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Disc
name: list-disc
category: Objects
---
## Sass

```scss
// Basic
@include o-list--disc('', 1.125em);

// Responsive
@include o-list--disc('', 1.125em, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--disc">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--disc@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list with native disc style.
 * @param  {String}  $alias
 * @param  {Number}  $padding
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Inline
name: list-inline
category: Objects
---
## Sass

```scss
// Basic
@include o-list--inline();

// Responsive
@include o-list--inline('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--inline">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--inline@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Leading Zero
name: list-leading-zero
category: Objects
---
## Sass

```scss
// Basic
@include o-list--leading-zero();

// Responsive
@include o-list--leading-zero('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--leading-zero">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--leading-zero@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with leading zeroes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Number
name: list-number
category: Objects
---
## Sass

```scss
// Basic
@include o-list--number();

// Responsive
@include o-list--number('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--number">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--number@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Roman
name: list-roman
category: Objects
---
## Sass

```scss
// Basic
@include o-list--roman();

// Responsive
@include o-list--roman('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--roman">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--roman@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with Roman numerals.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Slash
name: list-slash
category: Objects
---
## Sass

```scss
// Basic
@include o-list--slash();

// Responsive
@include o-list--slash('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--slash">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--slash@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by slashes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Media
name: media
category: Objects
---

## Sass

```scss
// Basic
@include o-media();
@include o-media__figure();
@include o-media__body();

// Responsive
@include o-media('sm');
```

## Markup

### Basic

```html_example
<div class="o-media  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```

### Responsive

```html_example
<div class="o-media@sm  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm@sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```
*/
/**
 * Flexible media objects.
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object figure.
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object body.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Section
name: section
category: Objects
---
## Sass

```scss
// Basic
@include o-section();

// Responsive
@include o-section('sm');
```

## Markup

### Basic

```html_example
<section class="o-section">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```

### Responsive

```html_example
<section class="o-section@sm">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```
*/
/**
 * Section object.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Table
name: table
category: Objects
---
## Sass

```scss
// Basic
@include o-table();
@include o-table__item();

// Responsive
@include o-table('sm');
```

## Markup

### Basic

```html_example
<div class="o-table">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-table@sm">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Table object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Table object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Video
name: video
category: Objects
---
## Sass

```scss
// Basic
@include o-video();
@include o-video__close();
@include o-video__element();
@include o-video--overlay();

// Responsive
@include o-video('sm');
```

## Markup

### Basic

```html_example
<figure class="o-video  /  js-video-wrapper">
  <div class="u-aspect-ratio-16x9"></div>
  <div id="video" class="o-video__element  /  js-video" data-video='{
    "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
  }'></div>
</figure>
```

### Overlay

```html_example
<div class="o-video  o-video--overlay  /  js-video-wrapper">
  <img src="./img/spacex-interplanetary-transport-system.jpg" width="1920" height="1080" alt="SpaceX Interplanetary Transport System">
  <button class="o-video__play  /  u-position-center  /  js-video-open" type="button" data-video="#video-overlay">Play Video</button>
  <figure class="o-video__wrapper">
    <button class="o-video__close  /  js-video-close" type="button">Close</button>
    <div id="video-overlay" class="o-video__element  /  js-video" data-video='{
      "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
    }'></div>
  </figure>
</div>
```
*/
/**
 * Video object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object wrapper.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object element.
 * @see https://embedresponsively.com/
 * @note Since YouTube automatically letterboxes videos, we don't have to worry about aspect ratio or centering
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object play button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object close button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video overlay.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Browser Upgrade
name: browser-upgrade
category: Components
---
## Sass

```scss
@include c-browser-upgrade();
```

## Markup

### Basic

```html_example
<p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
```

### Internet Explorer 9 and below

```html_example
<!--[if IE]><p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p><![endif]-->
```
*/
/**
 * Browser upgrade prompt for old Internet Explorer.
 */
/*doc
---
title: Align Items
name: align-items
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-items('center');

// Responsive
@include u-align-items('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-items utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Align Self
name: align-self
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-self('flex-start');
@include u-align-self('flex-end');

// Responsive
@include u-align-self('flex-start', 'sm');
@include u-align-self('flex-end', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-self utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Animation
name: animation
category: Utilities
---
## Sass

```scss
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Basic
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards) {
  opacity: 1;
}

// Responsive
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards, 'sm') {
  opacity: 1;
}
```

## Markup

### Basic

```html_example
<img class="u-animation-fade-in-400ms" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-animation-fade-in-400ms@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Animation utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Aspect Ratio
name: aspect-ratio
category: Utilities
---
<span class="todo">TODO:</span> Make `btwn` alias for mq (e.g., `u-aspect-ratio--3x2@btwn-sm-and-md`).

## Sass

```scss
// Basic
@include u-aspect-ratio(3, 1);
@include u-aspect-ratio(16, 9);

// Responsive
@include u-aspect-ratio(3, 1, 'sm');
@include u-aspect-ratio(16, 9, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-16x9"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-3x1  u-aspect-ratio-16x9@sm"></div>
</div>
```
*/
/**
 * Aspect ratio utility.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Background Color
name: background-color
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-color('gray-1', $color-gray-1);

// Responsive
@include u-background-color('gray-1', $color-gray-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Background-color utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Background Position
name: background-position
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-position('top-right', top right);

// Responsive
@include u-background-position('top-right', top right, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-position-top-right" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-position-top-right@sm" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```
*/
/**
 * Background-position utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Border
name: border
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-border('top-gray-3', 1px solid $color-gray-3);

// Responsive
@include u-border('top-gray-3', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-border-top-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-border-top-gray-3@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Border utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Box Shadow
name: box-shadow
category: Utilities
---
## Sass

```scss
// Basic
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1));

// Responsive
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-box-shadow-down-black-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-box-shadow-down-black-1@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Box-shadow utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Color
name: color
category: Utilities
---
## Sass

```scss
// Basic
@include u-color('red-1', $color-red-1);

// Responsive
@include u-color('red-1', $color-red-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Color utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Content
name: content
category: Utilities
---
## Sass

```scss
// Basic
@include u-content-before('arrow-left', '« ');
@include u-content-after('arrow-right', ' »');

// Responsive
@include u-content-before('arrow-left', '« ', 'sm');
@include u-content-after('arrow-right', ' »', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-content-before-arrow-left  u-content-after-arrow-right">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```

### Responsive

```html_example
<div class="u-content-before-arrow-left@sm  u-content-after-arrow-right@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```
*/
/**
 * Content utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Depth (z-index)
name: depth
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-depth\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-depth(1);
@include u-depth(2);
@include u-depth(3);

// Responsive
@include u-depth(1, 'sm');
@include u-depth(2, 'sm');
@include u-depth(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```

### Responsive

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```
*/
/**
 * Depth utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Display
name: display
category: Utilities
---
## Sass

```scss
// Basic
@include u-display('block');
@include u-visually-hidden();

// Responsive
@include u-display('block', 'sm');
@include u-visually-hidden('sm');
```

## Markup

### Basic

```html_example
<span class="u-display-block  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```

### Responsive

```html_example
<span class="u-display-block@sm  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```
*/
/**
 * Display utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Visually hidden utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex
name: flex
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex('0', 0);
@include u-flex('1', 1);

// Responsive
@include u-flex('0', 0, 'sm');
@include u-flex('1', 1, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-flex-0" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-flex-0@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```
*/
/**
 * Flex utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Direction
name: flex-direction
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-direction('row-reverse');

// Responsive
@include u-flex-direction('row-reverse', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex direction utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Wrap
name: flex-wrap
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-wrap('wrap');

// Responsive
@include u-flex-wrap('wrap', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap@sm">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex-wrap utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Float
name: float
category: Utilities
---
## Sass

```scss
// Basic
@include u-float('right');
@include u-float('left');
@include u-clearfix('right');

// Responsive
@include u-float('right', 'sm');
@include u-float('left', 'sm');
@include u-clearfix('sm');
```

## Markup

### Basic

```html_example
<p class="u-clearfix">
  <img class="u-float-right" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```

### Responsive

```html_example
<p class="u-clearfix">
  <img class="u-float-right@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```
*/
/**
 * Float utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Clearfix utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Family
name: font-family
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-family('display', $font-family--display);

// Responsive
@include u-font-family('display', $font-family--display, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-family-display">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-family-display@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-family utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Size
name: font-size
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-size('100%', 100%);
@include u-font-size('heading', 1.5rem);

// Responsive
@include u-font-size('100%', 100%, 'sm');
@include u-font-size('heading', 1.5rem, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-size-100%">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-size-100%@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-size utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Style
name: font-style
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-style('italic');

// Responsive
@include u-font-style('italic', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-style-italic">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-style-italic@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-style utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Weight
name: font-weight
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-weight('400', 400);
@include u-font-weight('bold', bold);

// Responsive
@include u-font-weight('400', 400, 'sm');
@include u-font-weight('bold', bold, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-weight-400">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-weight-400@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font weight utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Height
name: height
category: Utilities
---
## Sass

```scss
// Basic
@include u-height('50vh', 50vh);

// Responsive
@include u-height('50vh', 50vh, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-height-50vh" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-height-50vh@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Hover
name: hover
category: Utilities
---
<span class="todo">TODO:</span> How can we include default properties like `transition`?

## Sass

```scss
// Basic
@include u-hover('dim', 'is-active') {
  opacity: 0.6;
}

// Responsive
@include u-hover('dim', 'is-active', 'sm') {
  opacity: 0.6;
}
```

## Markup

### Basic

```html_example
<img class="u-hover-dim" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-hover-dim@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Hover utility.
 * @param  {String}  $alias
 * @param  {Map}     $active-class
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Justify Content
name: justify-content
category: Utilities
---
## Sass

```scss
// Basic
@include u-justify-content('center');

// Responsive
@include u-justify-content('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-justify-content-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-justify-content-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Justify-content utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Line Height
name: line-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-line-height('0.8', 0.8);

// Responsive
@include u-line-height('0.8', 0.8, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-line-height-0.8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```

### Responsive

```html_example
<p class="u-line-height-0.8@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```
*/
/**
 * Line-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Margin
name: margin
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-margin('horizontal-auto', auto);

// Responsive
@include u-margin('horizontal-auto', auto, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-margin-horizontal-auto" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-margin-horizontal-auto@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Margin utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Height
name: max-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-height('100', rem(100));

// Responsive
@include u-max-height('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-height-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-height-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Width
name: max-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-width('100', rem(100));

// Responsive
@include u-max-width('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-width-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-width-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Height
name: min-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-height('200', rem(200));

// Responsive
@include u-min-height('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-height-200" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-height-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```
*/
/**
 * Min-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Width
name: min-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-width('200', rem(200));

// Responsive
@include u-min-width('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-width-200" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-width-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Min-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Object Fit
name: object-fit
category: Utilities
---
<span class="todo">Important:</span> Requires polyfill for IE and Edge: [Can I use…](https://caniuse.com/#feat=object-fit) Use either [lazySizes object fit extension](https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit) or [object-fit-images](https://github.com/bfred-it/object-fit-images)

Values: `fill`, `contain`, `cover`, `none`, `scale-down`.

## Sass

```scss
// Basic
@include u-object-fit('cover', cover);
@include u-object-fit('cover-100%', cover) {
  width: 100%;
  height: 100%;
}

// Responsive
@include u-object-fit('cover', cover, 'sm');
@include u-object-fit('cover-100%', cover, 'sm') {
  width: 100%;
  height: 100%;
}
```

## Markup

### Basic

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```

### Responsive

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```
*/
/**
 * Object-fit utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Opacity
name: opacity
category: Utilities
---
## Sass

```scss
// Basic
@include u-opacity('60', 0.6);

// Responsive
@include u-opacity('60', 0.6, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-opacity-60" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-opacity-60@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Opacity utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Order
name: order
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-order\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-order(1);
@include u-order(2);
@include u-order(3);

// Responsive
@include u-order(1, 'sm');
@include u-order(2, 'sm');
@include u-order(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-order-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-order-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Order utility.
 * @param  {Number}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Overflow
name: overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-overflow('hidden');

// Responsive
@include u-overflow('hidden', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-width-100  /  u-overflow-hidden">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-width-100  /  u-overflow-hidden@sm">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```
*/
/**
 * Overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Padding
name: padding
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-padding('horizontal-sm', $gutter-sm);

// Responsive
@include u-padding('horizontal-sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-padding-horizontal-sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-padding-horizontal-sm@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Padding utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Pointer Events
name: pointer-events
category: Utilities
---
## Sass

```scss
// Basic
@include u-pointer-events('none');

// Responsive
@include u-pointer-events('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-pointer-events-none">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```

### Responsive

```html_example
<p class="u-pointer-events-none@sm">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```
*/
/**
 * Pointer-events utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Position
name: position
category: Utilities
---
## Sass

```scss
// Basic
@include u-position('relative', relative);
@include u-position('center', absolute) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Responsive
@include u-position('relative', relative, 'sm');
@include u-position('center', absolute, 'sm') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
```

## Markup

### Basic

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Position utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Align
name: text-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-align('center');

// Responsive
@include u-text-align('center', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-align-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-text-align-center@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Overflow
name: text-overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-overflow('ellipsis');

// Responsive
@include u-text-overflow('ellipsis', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Transform
name: text-transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-transform('uppercase');

// Responsive
@include u-text-transform('uppercase', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-transform-uppercase">Lorem ipsum dolor</p>
```

### Responsive

```html_example
<p class="u-text-transform-uppercase@sm">Lorem ipsum dolor</p>
```
*/
/**
 * Text-transform utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Transform
name: transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-transform('rotate-90', rotate(90deg));

// Responsive
@include u-transform('rotate-90', rotate(90deg), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-transform-rotate-90" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-transform-rotate-90@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Transform utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: User Select
name: user-select
category: Utilities
---
## Sass

```scss
// Basic
@include u-user-select('none');

// Responsive
@include u-user-select('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-user-select-none">Select me</p>
```

### Responsive

```html_example
<p class="u-user-select-none@sm">Select me</p>
```
*/
/**
 * User-select utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Vertical Align
name: vertical-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-vertical-align('middle');

// Responsive
@include u-vertical-align('middle', 'sm');
```

## Markup

### Basic

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```
*/
/**
 * Vertical-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: White Space
name: white-space
category: Utilities
---
## Sass

```scss
// Basic
@include u-white-space('nowrap');

// Responsive
@include u-white-space('nowrap', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-white-space-nowrap">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```

### Responsive

```html_example
<p class="u-white-space-nowrap@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```
*/
/**
 * White-space utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Width
name: width
category: Utilities
---
## Sass

```scss
// Basic
@include u-width('1/1', percentage(1 / 1));
@include u-width('1/2', percentage(1 / 2));

// Responsive
@include u-width('1/1', percentage(1 / 1), 'sm');
@include u-width('1/2', percentage(1 / 2), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/2" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/2" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```
*/
/**
 * Width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Escape special characters in a selector.
 * @param  {String}  $pixels
 * @return {String}
 */
/**
 * Remove the unit of a length
 * @see https://hugogiraudel.com/2013/08/12/sass-functions/
 * @param  {Number}  $value  Number to remove unit from
 * @return {Number}          Unitless number
 */
/**
 * Convert px values (e.g. from a PSD) to ems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @param  {Number}  $context  Defaults to $font-size
 * @return {Number}            em value
 */
/**
 * Convert px values (e.g. from a PSD) to rems.
 * @see https://viljamis.com/blog/2013/prototyping-responsive-typography/
 * @param  {Number}  $pixels
 * @return {Number}           rem value
 */
/**
 * Subtract 1px from breakpoint so min/max queries don't overlap.
 * @todo Use 0.01em instead of 1px to avoid media query "void" when text zoom is on.
 * @see https://github.com/sass-mq/sass-mq/issues/6
 * @param  {Number}  $breakpoint
 * @return {Number}
 */
/**
 * Convert tracking (e.g. from a PSD) to ems for letter-spacing.
 * @param  {Number}  $tracking
 * @return {Number}             em value
 */
/**
 * Convert px letter spacing to em based on font size
 */
/**
 * Convert line height to unit relative to the font size
 * @param % or px value
 */
/**
 * Uses clamp to set a fluid valid with min/max values using clamp()
 * By default, this uses $breakpoint-min and $breakpoint-max
 *
 * @param px value min
 * @param px value max at design size
 * @param bp-min
 * @param bp-max
 *
 */
/**
 * Casting any value to a string.
 * @see https://hugogiraudel.com/2014/01/27/casting-types-in-sass/
 * @param  {mixed}   $value
 * @return {String}
 */
/**
 * Replace `$search` with `$replace` in `$string`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/5c85bcae6ab51ef3996719ec31cce7aafd1a9fad/stylesheets/private/_str-replace.scss
 * @param  {String}  $string       - Initial string
 * @param  {String}  $search       - Substring to replace
 * @param  {String}  $replace ('') - New value
 * @return {String} - Updated string
 */
/**
 * Split `$string` into several parts using `$delimiter`.
 * @see https://github.com/HugoGiraudel/SassyStrings/blob/c587b74ad96532eb47ec8509deb8c212ba75478c/stylesheets/private/_str-explode.scss
 * @param  {String}  $string         - String to split
 * @param  {String}  $delimiter ('') - String to use as a delimiter to split `$string`
 * @return {List}
 */
/**
 * Global breakpoints for your application.
 * @note Always set the `em` context to 16 when you're using them for media
 * queries. Otherwise, if you change `$font-size` you'll get wonky values that
 * technically work but aren't what you wanted.
 * @see https://zellwk.com/blog/media-query-units/
 * @type {Map}
 */
/**
 * Accessible hover styles. Applies :hover styles to :focus and :active.
 * @see https://24ways.org/2007/css-for-accessibility
 */
/**
 * Hide only visually, but have it available for screen readers:
 * @see https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */
/**
 * Keep an element invisible until an active class is added to it.
 * @param  {String}  $active-class
 */
/**
 * From Foundation's visibility classes
 * @see https://github.com/zurb/foundation-sites/blob/develop/scss/components/_visibility.scss
 * @see https://foundation.zurb.com/sites/docs/v/5.5.3/components/visibility.html
 */
/**
 * Get full path to font file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Get full path to image file.
 * @param  {String}  $path
 * @return {String}
 */
/**
 * Clear floated elements.
 * @see https://css-tricks.com/snippets/css/clear-fix/
 */
/**
 * Polyfill object-fit and object-position.
 * Values: `fill`, `contain`, `cover`, `none`, `scale-down`.
 * @note Requires polyfill for IE and Edge.
 * @see https://caniuse.com/#feat=object-fit
 * @see https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit
 * @see https://github.com/bfred-it/object-fit-images
 * @param  {String}  $value
 */
/**
 * Input placeholders
 * @see https://github.com/twbs/bootstrap/blob/c04aa41ee2744b769be38526bbdef11e5be9421b/less/mixins/vendor-prefixes.less#L100-L109
 */
/**
 * Generic property utility.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Directional property utility.
 * Valid aliases: `top`, `gray`, `gray-3`, `top-gray`, `top-gray-3`.
 * @param  {String}  $property
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Generate fluid padding. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid margin. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate fluid spacing. padding: top left bottom right
 *
 * @param $top - single value or list (min px, max px)
 * @param $left - single value or list (min px, max px)
 * @param $bottom - single value or list (min px, max px)
 * @param $right - single value or list (min px, max px)
 */
/**
 * Generate a fluid property class. e.g u-padding-top-sm
 *
 * @param $property - e.g margin-top, padding-bottom
 * @param $alias - simple string indicating the size. e.g sm, lg
 * @param $value - list (min px, max px)
 * @param $bp-min - min breakpoint
 * @param $bp-max - max-breakpoint
 */
/**
 * Add new breakpoint alias.
 * @see https://oddbird.net/2013/10/19/map-merge
 * @see https://github.com/sass/sass/issues/1511#issuecomment-61907897
 *
 * Example:
 * `@include add-breakpoint('sidebar', 60em);`
 *
 * @param  {String}  $alias
 * @param  {Number}  $value
 * @return {Boolean}
 */
/**
 * Generate media queries based on a map of `$breakpoints`. Defaults to
 * mobile-first, min-width queries.
 *
 * Allows quick prefixes to modify breakpoints:
 * - `lt-`: Desktop-first, max-width queries (automatically removes 1px to avoid query overlap)
 * - `btwn-`: Between min- and max-width queries
 *
 * Based on Inuit's responsive tools:
 * @see https://github.com/inuitcss/settings.responsive/blob/master/_settings.responsive.scss
 * @see https://github.com/inuitcss/tools.responsive/blob/master/_tools.responsive.scss
 *
 * And Sass MQ:
 * @see https://github.com/sass-mq/sass-mq
 *
 * @note `$breakpoints` is defined in `resources/assets/sass/settings/_breakpoints.scss`
 *
 * @param  {String}  $breakpoint-name
 */
/**
 * Generate breakpoint-specific classes.
 * @param  {String}  $alias
 * @param  {String}  $at-breakpoint
 */
/**
 * Build an SVG. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String}  $icon
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Build an SVG path. Based on Noah Blon's work.
 * @see https://codepen.io/noahblon/pen/xGbXdV
 * @param  {String} $path
 * @param  {Map}    $parameters
 * @return {String}
 */
/**
 * Optimize SVGs in data URIs. Based on Taylor Hunt's and jakob-e's work.
 * @see https://codepen.io/tigt/post/optimizing-svgs-in-data-uris
 * @see https://codepen.io/jakob-e/pen/doMoML
 * @param  {String}  $svg
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @return {String}
 */
/**
 * Font smoothing on OS X.
 * @see https://maximilianhoffmann.com/posts/better-font-rendering-on-osx
 * @param  {Boolean}  $value
 * @return {[type]}
 */
/*doc
---
title: Grid
name: grid
category: Objects
---
## Sass

```scss
// Basic
@include o-grid();
@include o-grid__item();

// Responsive
@include o-grid('sm');
```

## Markup

### Basic

```html_example
<div class="o-grid">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid@sm">
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/1  u-width-1/2@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Grid object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Grid Gutters
name: grid-gutters
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-grid--gutters('sm', $gutter-sm);

// Responsive
@include o-grid--gutters('sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="o-grid  o-grid--gutters-sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-grid  o-grid--gutters-sm@sm">
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-grid__item  /  u-width-1/2">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Grid with gutters.
 * @param  {String}  $alias
 * @param  {Number}  $size
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Icon
name: icon
category: Objects
---
<span class="todo">IMPORTANT:</span> When preparing SVGs:

1. Remove every `fill` and `stroke` attribute that should inherit its color from CSS.
2. Set `fill="none"` or `stroke="none"` anywhere a color should _not_ be applied.

## Sass

```scss
// Basic
@include o-icon();
@include o-icon--size(70, 70);

// Responsive
@include o-icon('sm');
@include o-icon--size(120, 120, 'sm');
```

## Markup

### Basic

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```

### Responsive

```html_example
<i class="o-icon  o-icon--mode-logo  o-icon--70x70  o-icon--120x120@sm">
  <svg>
    <use xlink:href="./img/svg/sprites/global.svg#mode-logo"></use>
  </svg>
</i>
```
*/
/**
 * Icon object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Icon size helper.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List
name: list
category: Objects
---
## Sass

```scss
// Basic
@include o-list();
@include o-list__item();

// Responsive
@include o-list('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * List object.
 * @param  {String}  $at-breakpoint
 */
/**
 * List object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Border
name: list-border
category: Objects
---
<span class="todo">TODO:</span> Allow directional gutters (e.g., `all`, `horizontal`, or `vertical`).

## Sass

```scss
// Basic
@include o-list--border('', 1px solid $color-gray-3);

// Responsive
@include o-list--border('', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--border">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--border@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Block list separated by borders.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Bullet
name: list-bullet
category: Objects
---
## Sass

```scss
// Basic
@include o-list--bullet('', 1rem);

// Responsive
@include o-list--bullet('', 1rem, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--bullet">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--bullet@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list.
 * @param  {String}  $alias
 * @param  {Number}  $margin
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Comma
name: list-comma
category: Objects
---
## Sass

```scss
// Basic
@include o-list--comma();

// Responsive
@include o-list--comma('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--comma">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--comma@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by commas.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Disc
name: list-disc
category: Objects
---
## Sass

```scss
// Basic
@include o-list--disc('', 1.125em);

// Responsive
@include o-list--disc('', 1.125em, 'sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--disc">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--disc@sm">
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ul>
```
*/
/**
 * Bulleted list with native disc style.
 * @param  {String}  $alias
 * @param  {Number}  $padding
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Inline
name: list-inline
category: Objects
---
## Sass

```scss
// Basic
@include o-list--inline();

// Responsive
@include o-list--inline('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--inline">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--inline@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Leading Zero
name: list-leading-zero
category: Objects
---
## Sass

```scss
// Basic
@include o-list--leading-zero();

// Responsive
@include o-list--leading-zero('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--leading-zero">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--leading-zero@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with leading zeroes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Number
name: list-number
category: Objects
---
## Sass

```scss
// Basic
@include o-list--number();

// Responsive
@include o-list--number('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--number">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--number@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Roman
name: list-roman
category: Objects
---
## Sass

```scss
// Basic
@include o-list--roman();

// Responsive
@include o-list--roman('sm');
```

## Markup

### Basic

```html_example
<ol class="o-list  o-list--roman">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```

### Responsive

```html_example
<ol class="o-list  o-list--roman@sm">
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
  <li class="o-list__item"><span class="o-list__counter"></span> Lorem ipsum dolor sit amet, consectetur adipisicing elit.</li>
</ol>
```
*/
/**
 * Numbered list with Roman numerals.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: List Slash
name: list-slash
category: Objects
---
## Sass

```scss
// Basic
@include o-list--slash();

// Responsive
@include o-list--slash('sm');
```

## Markup

### Basic

```html_example
<ul class="o-list  o-list--slash">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```

### Responsive

```html_example
<ul class="o-list  o-list--slash@sm">
  <li class="o-list__item">Lorem</li>
  <li class="o-list__item">Ipsum</li>
  <li class="o-list__item">Dolor</li>
</ul>
```
*/
/**
 * Inline list separated by slashes.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Media
name: media
category: Objects
---

## Sass

```scss
// Basic
@include o-media();
@include o-media__figure();
@include o-media__body();

// Responsive
@include o-media('sm');
```

## Markup

### Basic

```html_example
<div class="o-media  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```

### Responsive

```html_example
<div class="o-media@sm  /  u-align-items-center">
  <figure class="o-media__figure  /  u-padding-right-sm@sm">
    <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  </figure>
  <p class="o-media__body">
    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  </p>
</div>
```
*/
/**
 * Flexible media objects.
 * @see https://philipwalton.github.io/solved-by-flexbox/demos/media-object/
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object figure.
 * @param  {String}  $at-breakpoint
 */
/**
 * Media object body.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Section
name: section
category: Objects
---
## Sass

```scss
// Basic
@include o-section();

// Responsive
@include o-section('sm');
```

## Markup

### Basic

```html_example
<section class="o-section">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```

### Responsive

```html_example
<section class="o-section@sm">
  <h4>Lorem ipsum</h4>
  <p>Dolor sit amet, consectetur adipisicing elit.</p>
</section>
```
*/
/**
 * Section object.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Table
name: table
category: Objects
---
## Sass

```scss
// Basic
@include o-table();
@include o-table__item();

// Responsive
@include o-table('sm');
```

## Markup

### Basic

```html_example
<div class="o-table">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```

### Responsive

```html_example
<div class="o-table@sm">
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
  <p class="o-table__item">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
</div>
```
*/
/**
 * Table object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Table object item.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Video
name: video
category: Objects
---
## Sass

```scss
// Basic
@include o-video();
@include o-video__close();
@include o-video__element();
@include o-video--overlay();

// Responsive
@include o-video('sm');
```

## Markup

### Basic

```html_example
<figure class="o-video  /  js-video-wrapper">
  <div class="u-aspect-ratio-16x9"></div>
  <div id="video" class="o-video__element  /  js-video" data-video='{
    "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
  }'></div>
</figure>
```

### Overlay

```html_example
<div class="o-video  o-video--overlay  /  js-video-wrapper">
  <img src="./img/spacex-interplanetary-transport-system.jpg" width="1920" height="1080" alt="SpaceX Interplanetary Transport System">
  <button class="o-video__play  /  u-position-center  /  js-video-open" type="button" data-video="#video-overlay">Play Video</button>
  <figure class="o-video__wrapper">
    <button class="o-video__close  /  js-video-close" type="button">Close</button>
    <div id="video-overlay" class="o-video__element  /  js-video" data-video='{
      "url": "https://www.youtube.com/watch?v=0qo78R_yYFA"
    }'></div>
  </figure>
</div>
```
*/
/**
 * Video object.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object wrapper.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object element.
 * @see https://embedresponsively.com/
 * @note Since YouTube automatically letterboxes videos, we don't have to worry about aspect ratio or centering
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object play button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video object close button.
 * @param  {String}  $at-breakpoint
 */
/**
 * Video overlay.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Browser Upgrade
name: browser-upgrade
category: Components
---
## Sass

```scss
@include c-browser-upgrade();
```

## Markup

### Basic

```html_example
<p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p>
```

### Internet Explorer 9 and below

```html_example
<!--[if IE]><p class="c-browser-upgrade">You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/">upgrade your browser</a> to improve your experience.</p><![endif]-->
```
*/
/**
 * Browser upgrade prompt for old Internet Explorer.
 */
/*doc
---
title: Align Items
name: align-items
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-items('center');

// Responsive
@include u-align-items('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-items utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Align Self
name: align-self
category: Utilities
---
## Sass

```scss
// Basic
@include u-align-self('flex-start');
@include u-align-self('flex-end');

// Responsive
@include u-align-self('flex-start', 'sm');
@include u-align-self('flex-end', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-align-items-center">
  <img class="u-align-self-flex-start@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
  <img class="u-align-self-flex-end@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Align-self utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Animation
name: animation
category: Utilities
---
## Sass

```scss
@keyframes fade-in {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// Basic
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards) {
  opacity: 1;
}

// Responsive
@include u-animation('fade-in-400ms', 400ms fade-in linear forwards, 'sm') {
  opacity: 1;
}
```

## Markup

### Basic

```html_example
<img class="u-animation-fade-in-400ms" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-animation-fade-in-400ms@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Animation utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Aspect Ratio
name: aspect-ratio
category: Utilities
---
<span class="todo">TODO:</span> Make `btwn` alias for mq (e.g., `u-aspect-ratio--3x2@btwn-sm-and-md`).

## Sass

```scss
// Basic
@include u-aspect-ratio(3, 1);
@include u-aspect-ratio(16, 9);

// Responsive
@include u-aspect-ratio(3, 1, 'sm');
@include u-aspect-ratio(16, 9, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-16x9"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-color-gray-1">
  <div class="u-aspect-ratio-3x1  u-aspect-ratio-16x9@sm"></div>
</div>
```
*/
/**
 * Aspect ratio utility.
 * @param  {Number}  $width
 * @param  {Number}  $height
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Background Color
name: background-color
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-color('gray-1', $color-gray-1);

// Responsive
@include u-background-color('gray-1', $color-gray-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-background-color-gray-3">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Background-color utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Background Position
name: background-position
category: Utilities
---
## Sass

```scss
// Basic
@include u-background-position('top-right', top right);

// Responsive
@include u-background-position('top-right', top right, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-background-position-top-right" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```

### Responsive

```html_example
<div class="u-background-position-top-right@sm" style="background-image: url('https://placehold.it/100/ff0000/ffffff?text=1'); background-size: 100px; background-repeat: no-repeat;">
  <div class="u-aspect-ratio-3x1"></div>
</div>
```
*/
/**
 * Background-position utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Border
name: border
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-border('top-gray-3', 1px solid $color-gray-3);

// Responsive
@include u-border('top-gray-3', 1px solid $color-gray-3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-border-top-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-border-top-gray-3@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Border utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Box Shadow
name: box-shadow
category: Utilities
---
## Sass

```scss
// Basic
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1));

// Responsive
@include u-box-shadow('down-black-1', 0 2px 5px rgba($color-black-1, 0.1), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-box-shadow-down-black-1">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```

### Responsive

```html_example
<div class="u-box-shadow-down-black-1@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</div>
```
*/
/**
 * Box-shadow utility.
 * @param  {String} $alias
 * @param  {String} $value
 * @param  {String} $at-breakpoint
 */
/*doc
---
title: Color
name: color
category: Utilities
---
## Sass

```scss
// Basic
@include u-color('red-1', $color-red-1);

// Responsive
@include u-color('red-1', $color-red-1, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p class="u-color-red-1">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</p>
```
*/
/**
 * Color utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Content
name: content
category: Utilities
---
## Sass

```scss
// Basic
@include u-content-before('arrow-left', '« ');
@include u-content-after('arrow-right', ' »');

// Responsive
@include u-content-before('arrow-left', '« ', 'sm');
@include u-content-after('arrow-right', ' »', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-content-before-arrow-left  u-content-after-arrow-right">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```

### Responsive

```html_example
<div class="u-content-before-arrow-left@sm  u-content-after-arrow-right@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
</div>
```
*/
/**
 * Content utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Depth (z-index)
name: depth
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-depth\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-depth(1);
@include u-depth(2);
@include u-depth(3);

// Responsive
@include u-depth(1, 'sm');
@include u-depth(2, 'sm');
@include u-depth(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```

### Responsive

```html_example
<div class="u-position-relative">
  <div class="u-aspect-ratio-3x1"></div>
  <img class="u-depth-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1" style="position: absolute; top: 20px; left: 20px;">
  <img class="u-depth-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2" style="position: absolute; top: 40px; left: 40px;">
  <img class="u-depth-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3" style="position: absolute; top: 60px; left: 60px;">
</div>
```
*/
/**
 * Depth utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Display
name: display
category: Utilities
---
## Sass

```scss
// Basic
@include u-display('block');
@include u-visually-hidden();

// Responsive
@include u-display('block', 'sm');
@include u-visually-hidden('sm');
```

## Markup

### Basic

```html_example
<span class="u-display-block  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```

### Responsive

```html_example
<span class="u-display-block@sm  /  u-background-color-gray-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</span>
```
*/
/**
 * Display utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Visually hidden utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex
name: flex
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex('0', 0);
@include u-flex('1', 1);

// Responsive
@include u-flex('0', 0, 'sm');
@include u-flex('1', 1, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-flex-0" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-flex-0@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-flex-1@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
</div>
```
*/
/**
 * Flex utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Direction
name: flex-direction
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-direction('row-reverse');

// Responsive
@include u-flex-direction('row-reverse', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-direction-row-reverse@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex direction utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Flex Wrap
name: flex-wrap
category: Utilities
---
## Sass

```scss
// Basic
@include u-flex-wrap('wrap');

// Responsive
@include u-flex-wrap('wrap', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap@sm">
  <img src="https://placehold.it/300x100/ff0000/ffffff?text=1" width="300" height="100" alt="Item 1">
  <img src="https://placehold.it/300x100/00ff00/ffffff?text=2" width="300" height="100" alt="Item 2">
  <img src="https://placehold.it/300x100/0000ff/ffffff?text=3" width="300" height="100" alt="Item 3">
</div>
```
*/
/**
 * Flex-wrap utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Float
name: float
category: Utilities
---
## Sass

```scss
// Basic
@include u-float('right');
@include u-float('left');
@include u-clearfix('right');

// Responsive
@include u-float('right', 'sm');
@include u-float('left', 'sm');
@include u-clearfix('sm');
```

## Markup

### Basic

```html_example
<p class="u-clearfix">
  <img class="u-float-right" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```

### Responsive

```html_example
<p class="u-clearfix">
  <img class="u-float-right@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</p>
```
*/
/**
 * Float utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/**
 * Clearfix utility.
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Family
name: font-family
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-family('display', $font-family--display);

// Responsive
@include u-font-family('display', $font-family--display, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-family-display">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-family-display@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-family utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Size
name: font-size
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-size('100%', 100%);
@include u-font-size('heading', 1.5rem);

// Responsive
@include u-font-size('100%', 100%, 'sm');
@include u-font-size('heading', 1.5rem, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-size-100%">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-size-100%@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-size-heading@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-size utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Style
name: font-style
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-style('italic');

// Responsive
@include u-font-style('italic', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-font-style-italic">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-font-style-italic@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font-style utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Font Weight
name: font-weight
category: Utilities
---
## Sass

```scss
// Basic
@include u-font-weight('400', 400);
@include u-font-weight('bold', bold);

// Responsive
@include u-font-weight('400', 400, 'sm');
@include u-font-weight('bold', bold, 'sm');
```

## Markup

### Basic

```html_example
<h4 class="u-font-weight-400">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<h4 class="u-font-weight-400@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</h4>
<p class="u-font-weight-bold@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Font weight utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Height
name: height
category: Utilities
---
## Sass

```scss
// Basic
@include u-height('50vh', 50vh);

// Responsive
@include u-height('50vh', 50vh, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-height-50vh" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-height-50vh@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Hover
name: hover
category: Utilities
---
<span class="todo">TODO:</span> How can we include default properties like `transition`?

## Sass

```scss
// Basic
@include u-hover('dim', 'is-active') {
  opacity: 0.6;
}

// Responsive
@include u-hover('dim', 'is-active', 'sm') {
  opacity: 0.6;
}
```

## Markup

### Basic

```html_example
<img class="u-hover-dim" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-hover-dim@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Hover utility.
 * @param  {String}  $alias
 * @param  {Map}     $active-class
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Justify Content
name: justify-content
category: Utilities
---
## Sass

```scss
// Basic
@include u-justify-content('center');

// Responsive
@include u-justify-content('center', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-justify-content-center">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-justify-content-center@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Justify-content utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Line Height
name: line-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-line-height('0.8', 0.8);

// Responsive
@include u-line-height('0.8', 0.8, 'sm');
```

## Markup

### Basic

```html_example
<p class="u-line-height-0.8">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```

### Responsive

```html_example
<p class="u-line-height-0.8@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita reprehenderit aspernatur, non ratione excepturi et quia recusandae repellendus natus, libero facilis doloremque sint inventore voluptatem, voluptatibus odio sapiente iste officiis.</p>
```
*/
/**
 * Line-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Margin
name: margin
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-margin('horizontal-auto', auto);

// Responsive
@include u-margin('horizontal-auto', auto, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-margin-horizontal-auto" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-margin-horizontal-auto@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Margin utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Height
name: max-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-height('100', rem(100));

// Responsive
@include u-max-height('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-height-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-height-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Max Width
name: max-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-max-width('100', rem(100));

// Responsive
@include u-max-width('100', rem(100), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-max-width-100" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```

### Responsive

```html_example
<img class="u-max-width-100@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
```
*/
/**
 * Max-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Height
name: min-height
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-height('200', rem(200));

// Responsive
@include u-min-height('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-height-200" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-height-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" height="100" height="100" alt="Item 1">
```
*/
/**
 * Min-height utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Min Width
name: min-width
category: Utilities
---
## Sass

```scss
// Basic
@include u-min-width('200', rem(200));

// Responsive
@include u-min-width('200', rem(200), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-min-width-200" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-min-width-200@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Min-width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Object Fit
name: object-fit
category: Utilities
---
<span class="todo">Important:</span> Requires polyfill for IE and Edge: [Can I use…](https://caniuse.com/#feat=object-fit) Use either [lazySizes object fit extension](https://github.com/aFarkas/lazysizes/tree/gh-pages/plugins/object-fit) or [object-fit-images](https://github.com/bfred-it/object-fit-images)

Values: `fill`, `contain`, `cover`, `none`, `scale-down`.

## Sass

```scss
// Basic
@include u-object-fit('cover', cover);
@include u-object-fit('cover-100%', cover) {
  width: 100%;
  height: 100%;
}

// Responsive
@include u-object-fit('cover', cover, 'sm');
@include u-object-fit('cover-100%', cover, 'sm') {
  width: 100%;
  height: 100%;
}
```

## Markup

### Basic

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```

### Responsive

```html_example
<img class="u-width-1/1  /  u-height-200  /  u-object-fit-cover@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">

<figure class="u-height-200">
  <img class="u-object-fit-cover-100%@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</figure>
```
*/
/**
 * Object-fit utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Opacity
name: opacity
category: Utilities
---
## Sass

```scss
// Basic
@include u-opacity('60', 0.6);

// Responsive
@include u-opacity('60', 0.6, 'sm');
```

## Markup

### Basic

```html_example
<img class="u-opacity-60" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-opacity-60@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Opacity utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Order
name: order
category: Utilities
---
<span class="todo">TODO:</span> How should we handle negative values? For example, should we use parens: `.u-order\(-1\) { z-index: -1; }`.

## Sass

```scss
// Basic
@include u-order(1);
@include u-order(2);
@include u-order(3);

// Responsive
@include u-order(1, 'sm');
@include u-order(2, 'sm');
@include u-order(3, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex">
  <img class="u-order-2" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```

### Responsive

```html_example
<div class="u-display-flex">
  <img class="u-order-2@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
  <img class="u-order-3@sm" src="https://placehold.it/100/00ff00/ffffff?text=2" width="100" height="100" alt="Item 2">
  <img class="u-order-1@sm" src="https://placehold.it/100/0000ff/ffffff?text=3" width="100" height="100" alt="Item 3">
</div>
```
*/
/**
 * Order utility.
 * @param  {Number}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Overflow
name: overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-overflow('hidden');

// Responsive
@include u-overflow('hidden', 'sm');
```

## Markup

### Basic

```html_example
<div class="u-width-100  /  u-overflow-hidden">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-width-100  /  u-overflow-hidden@sm">
  <img src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
</div>
```
*/
/**
 * Overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Padding
name: padding
category: Utilities
---
Directions: null, `vertical`, `horizontal`, `top`, `right`, `bottom`, `left`.

## Sass

```scss
// Basic
@include u-padding('horizontal-sm', $gutter-sm);

// Responsive
@include u-padding('horizontal-sm', $gutter-sm, 'sm');
```

## Markup

### Basic

```html_example
<div class="u-padding-horizontal-sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-padding-horizontal-sm@sm">
  <img src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Padding utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Pointer Events
name: pointer-events
category: Utilities
---
## Sass

```scss
// Basic
@include u-pointer-events('none');

// Responsive
@include u-pointer-events('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-pointer-events-none">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```

### Responsive

```html_example
<p class="u-pointer-events-none@sm">
  <a href="javascript:alert('Hello!');">Click me</a>
</p>
```
*/
/**
 * Pointer-events utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Position
name: position
category: Utilities
---
## Sass

```scss
// Basic
@include u-position('relative', relative);
@include u-position('center', absolute) {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Responsive
@include u-position('relative', relative, 'sm');
@include u-position('center', absolute, 'sm') {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
```

## Markup

### Basic

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```

### Responsive

```html_example
<div class="u-position-relative  /  u-height-200">
  <img class="u-position-center@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
</div>
```
*/
/**
 * Position utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Align
name: text-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-align('center');

// Responsive
@include u-text-align('center', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-align-center">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-text-align-center@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Overflow
name: text-overflow
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-overflow('ellipsis');

// Responsive
@include u-text-overflow('ellipsis', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```

### Responsive

```html_example
<p class="u-width-100  /  u-overflow-hidden  /  u-white-space-nowrap  /  u-text-overflow-ellipsis@sm">Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
```
*/
/**
 * Text-overflow utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Text Transform
name: text-transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-text-transform('uppercase');

// Responsive
@include u-text-transform('uppercase', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-text-transform-uppercase">Lorem ipsum dolor</p>
```

### Responsive

```html_example
<p class="u-text-transform-uppercase@sm">Lorem ipsum dolor</p>
```
*/
/**
 * Text-transform utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Transform
name: transform
category: Utilities
---
## Sass

```scss
// Basic
@include u-transform('rotate-90', rotate(90deg));

// Responsive
@include u-transform('rotate-90', rotate(90deg), 'sm');
```

## Markup

### Basic

```html_example
<img class="u-transform-rotate-90" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```

### Responsive

```html_example
<img class="u-transform-rotate-90@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="100" height="100" alt="Item 1">
```
*/
/**
 * Transform utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: User Select
name: user-select
category: Utilities
---
## Sass

```scss
// Basic
@include u-user-select('none');

// Responsive
@include u-user-select('none', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-user-select-none">Select me</p>
```

### Responsive

```html_example
<p class="u-user-select-none@sm">Select me</p>
```
*/
/**
 * User-select utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Vertical Align
name: vertical-align
category: Utilities
---
## Sass

```scss
// Basic
@include u-vertical-align('middle');

// Responsive
@include u-vertical-align('middle', 'sm');
```

## Markup

### Basic

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```

### Responsive

```html_example
<p>
  Lorem ipsum dolor sit amet <img class="u-display-inline-block  /  u-vertical-align-middle@sm" src="https://placehold.it/100/ff0000/ffffff?text=1" width="50" height="50" alt="Item 1"> consectetur adipisicing elit.
</p>
```
*/
/**
 * Vertical-align utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: White Space
name: white-space
category: Utilities
---
## Sass

```scss
// Basic
@include u-white-space('nowrap');

// Responsive
@include u-white-space('nowrap', 'sm');
```

## Markup

### Basic

```html_example
<p class="u-white-space-nowrap">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```

### Responsive

```html_example
<p class="u-white-space-nowrap@sm">
  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quam velit, molestias hic. Numquam rerum eum, quae! Quod voluptate tempore aut, molestias! Aut atque, quis animi ab fugiat. Eveniet, nam ducimus.
</p>
```
*/
/**
 * White-space utility.
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
/*doc
---
title: Width
name: width
category: Utilities
---
## Sass

```scss
// Basic
@include u-width('1/1', percentage(1 / 1));
@include u-width('1/2', percentage(1 / 2));

// Responsive
@include u-width('1/1', percentage(1 / 1), 'sm');
@include u-width('1/2', percentage(1 / 2), 'sm');
```

## Markup

### Basic

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/2" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/2" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```

### Responsive

```html_example
<div class="u-display-flex  /  u-flex-wrap-wrap">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/ff0000/ffffff?text=1" width="200" height="200" alt="Item 1">
  <img class="u-width-1/1  u-width-1/2@sm" src="https://placehold.it/200/00ff00/ffffff?text=2" width="200" height="200" alt="Item 2">
</div>
```
*/
/**
 * Width utility.
 * @param  {String}  $alias
 * @param  {String}  $value
 * @param  {String}  $at-breakpoint
 */
@keyframes come-in {
  to {
    opacity: 1;
  }
}
@keyframes come-in-scale {
  to {
    transform: scale(1) translate(-50%, -50%);
    opacity: 1;
  }
}
@keyframes slight-bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-6px);
  }
  60% {
    transform: translateY(-3px);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-12px);
  }
  60% {
    transform: translateY(-6px);
  }
}
main {
  background-image: url("/img/pinnacle/backgrounds/map.1743442783504.webp");
  background-repeat: no-repeat;
  background-position: right 76%;
  background-size: 200%;
}
@media all and (min-width: 75em) {
  main {
    background-position: right 66%;
    background-size: contain;
  }
}

.home .o-video.about-home {
  margin-top: 85px;
  margin-bottom: 73px;
}
@media all and (min-width: 75em) {
  .home .o-video.about-home {
    margin-top: clamp(9.5833333333rem, 12.7777777778vw, 13.375rem);
    margin-bottom: clamp(7.2916666667rem, 9.7222222222vw, 10.1875rem);
  }
}

.quality-promise {
  padding-top: 85px;
  padding-bottom: 75px;
}
@media all and (min-width: 75em) {
  .quality-promise {
    padding-top: clamp(9.5833333333rem, 12.7777777778vw, 13.375rem);
    padding-bottom: clamp(9.5833333333rem, 12.7777777778vw, 13.375rem);
  }
}
@media all and (min-width: 75em) {
  .quality-promise .section-header__heading-img {
    width: clamp(23.0729166667rem, 30.7638888889vw, 32.25rem);
  }
}
.quality-promise .section-header__copy {
  font-family: minion-pro-display, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums proportional-nums;
  line-height: 1.35;
  letter-spacing: 0.025em;
  font-size: 18px;
}
@media all and (min-width: 48.0625em) {
  .quality-promise .section-header__copy {
    font-size: 20px;
  }
}
@media all and (min-width: 75em) {
  .quality-promise .section-header__copy {
    font-size: clamp(1.1458333333rem, 1.5277777778vw, 1.5625rem);
  }
}

.feature-carousel {
  padding-top: 0;
  padding-bottom: 30px;
}
@media all and (min-width: 61.25em) {
  .feature-carousel {
    padding-top: clamp(5.9895833333rem, 7.9861111111vw, 8.375rem);
    padding-bottom: clamp(5.9895833333rem, 7.9861111111vw, 8.375rem);
  }
}